body {
  background-color: #fff;
}

#headerRoot {
  max-width: 752px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
}

#headerRoot img {
  width: 100%;
  max-width: 421px;
  height: auto;
}

#root {
}

.App {
  max-width: 752px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.NoBuildingFound {
  text-align: center;
  margin-top: 20px;
}

.NoBuildingFound img {
  width: 100%;
  max-width: 421px;
  height: auto;
  margin-bottom: 20px;
}

.languageSelectorBar {
  margin-top: 5px;
  margin-bottom: 5px;
}

.formDescription {
  padding: 30px;
}

.serviceDeskFormPanel {
  margin-top: 20px;
  margin-bottom: 60px;
}

.footer {
  margin-top: 20px;
}

.languageSelectorBar.active {
}

.languageSelectorBar.unactive {
  color: #000;
}

.loaderStyle {
  position: absolute;
  text-align: center;
  width: 421px;
  height: 75px;
  top: 50%;
  left: 50%;
  margin-top: -37px;
  margin-left: -232px;
}

.list-horizontal-display {
  display: inline-block;
}
